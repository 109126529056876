<template lang="">
    <div>
        <v-card lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h6">{{ $t('cms.add_user_to_folder') }}</span>
                <button type="button" class="btn-close" @click="onClickClose">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text class="py-5">
                <TargetUser :usersAssigned="usersAssigned" :isHideFolder="true" />
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn color="blue darken-1" text @click="onClickClose">{{ $t('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="onClickSave"> {{ $t('form.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import TargetUser from '../TargetUser';
import { FolderItem } from '../../../api';

export default {
    props: {
        isOpen: { type: Boolean, required: true },
        onAdd: { type: Function, required: true },
        onClose: { type: Function, required: true },
    },
    components: {
        TargetUser,
    },
    data() {
        return {
            usersAssigned: [],
        };
    },
    created() {
        this.getUserAssigned();
    },
    watch: {
        '$props.isOpen': function () {
            if (this.isOpen) {
                this.getUserAssigned();
            }
        },
    },
    methods: {
        async getUserAssigned() {
            try {
                const folder_id = this.$route.query.folder_id;
                const res = await FolderItem.getList({
                    fields: ['id', 'user_id', 'course_id', { user: ['$all', '$paranoid'] }],
                    where: { folder_id, course_id: { $is: null } },
                });
                this.usersAssigned = res?.results?.objects?.rows?.map((item) => ({
                    id: item?.user?.id,
                    title: item?.user?.email,
                    type: 'USER',
                    parent_id: null,
                }));
            } catch (error) {
                console.log('🚀 ~ file: AddUserToFolder.vue:44 ~ getUserAssigned ~ error:', error);
            }
        },

        onClickClose() {
            this.usersAssigned = [];
            this.onClose();
        },

        onClickSave() {
            const idList = this.usersAssigned.map((item) => item?.id);
            this.onClickClose();
            this.onAdd(idList);
        },
    },
};
</script>

<style scoped lang="scss"></style>
